export enum SpotifyLinkType {
  Artist = 'artist',
  Track = 'track',
}

export type SpotifyEmbedLink = {
  type: SpotifyLinkType
  src: string
}

export function parseSpotifyLink(link: string): SpotifyEmbedLink | undefined {
  try {
    let url = new URL(link)

    if (url.hostname === 'open.spotify.com') {
      let { pathname } = url

      return {
        type: pathname.includes('track')
          ? SpotifyLinkType.Track
          : SpotifyLinkType.Artist,
        src: pathname.includes('embed')
          ? link
          : `https://open.spotify.com/embed${pathname}`,
      }
    }
  } catch {
    return undefined
  }

  return undefined
}
